import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import location from "../../Assets/icons/contact/location.svg";
import contact from "../../Assets/icons/contact/contact.svg";
import email from "../../Assets/icons/contact/email.svg";
function ContactUs() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <div className="w-full overflow-hidden" id="contact">
      <div
        className="w-full bg-[#F8F5F0] sectionlayout lato "

      >
        <h2 className="uppercase lato text-center text-[#3E4E41] font-bold pt-[2.9rem]" style={{ fontSize: "clamp(50px,4vw,64px)" }} data-aos="fade-up">Contact US</h2>

        <div className="md:flex py-[3rem] md:py-[5rem]  border-b">
          <div
            className="md:w-1/2 mb-[3rem] md:mb-0 flex items-center justify-center md:items-start"
            data-aos="fade-up"
          >
            <div className="w-[97%] md:w-auto]" data-aos="fade-right">

              <p
                style={{ fontSize: "clamp(30px,3vw,45px)" }}
                className="text-[#3E4E41] font-bold  tracking-wide uppercase lato"
              >
                Get your consultation <br /> today!!
              </p>
              <div className="w-full flex items-center pt-10">
                <img src={contact} alt="Contact" className="w-[1.7rem]" />
                <p className="pl-2 md:pl-4 pt-2 font-semibold text-[#333333] text-[17px] md:text-[20px]">
                  +92 304 1110898
                </p>
              </div>


              <div className="w-full flex items-center pt-2.5">
                <img src={email} alt="Email" className="w-[1.7rem]" />
                <p className="pl-2 md:pl-4 pt-2 font-semibold text-[#333333] text-[17px] md:text-[20px]">
                  info@dynamicleo.com
                </p>
              </div>
              <div className="w-full flex items-center pt-2.5">
                <img src={location} alt="Location" className="w-[1.7rem]" />
                <p className="pl-2 md:pl-4 pt-2 font-semibold text-[#333333] text-[17px] md:text-[20px]">
                  Dynamic Leo, Gulberg Greens Islamabad
                </p>
              </div>
            </div>
          </div>
          <div className="md:w-1/2 flex items-center justify-center relative mt-[6.5rem] md:mt-0" data-aos="fade-left">
            <div
              className="bg-[#EFE8D8] shadow-md inline-block w-[97%] md:w-[80%] rounded-xl"
              data-aos="fade-up"
            >
              <form className="px-4 md:px-8 py-12">
                <div className="lg:flex gap-4">
                  <input
                    type="text"
                    placeholder="First Name*"
                    required
                    className="w-full p-3 md:p-4 rounded-xl border  text-[#828282] hover:outline-[#828282] focus:outline focus:outline-[#828282] focus:outline-2 mb-6 lg:mb-0"
                  />
                  <input
                    type="text"
                    placeholder="Last Name*"
                    required
                    className="w-full p-3 md:p-4 rounded-xl border  text-[#828282] hover:outline-[#828282] focus:outline focus:outline-[#828282] focus:outline-2"
                  />
                </div>
                <div className="pt-6">
                  <input
                    type="text"
                    placeholder="Email*"
                    required
                    className="w-full p-3 md:p-4 rounded-xl border  text-[#828282] hover:outline-[#828282] focus:outline focus:outline-[#828282] focus:outline-2"
                  />
                </div>
                <div className="pt-6">
                  <input
                    type="text"
                    placeholder="Phone*"
                    required
                    className="w-full p-3 md:p-4 rounded-xl border  text-[#828282] hover:outline-[#828282] focus:outline focus:outline-[#828282] focus:outline-2"
                  />
                </div>
                <div className="pt-6">
                  <textarea
                    placeholder="Your Message..."
                    rows="6"
                    required
                    className="w-full p-3 md:p-4 rounded-xl border  text-[#828282] hover:outline-[#f9f9f9] focus:outline focus:outline-[#828282] focus:outline-2"
                  ></textarea>
                </div>
                <div className="pt-6">
                  <button
                    style={{ fontSize: "clamp(16px,1.2vw,18px)" }}
                    className="w-full py-3 bg-[#3E4E41] rounded-[24px] text-white font-semibold"
                  >
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
