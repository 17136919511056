import React, { useState } from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import axios from "axios";

const CourseForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    occupation: "",
    phone_number: "",
    whatsapp_number: "",
    city: "",
    reference: "",
    mode: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await axios.post("https://api.dynamicleoecommerce.com/api/register", formData ,{ withCredentials: true});
      setIsLoading(false);
      setIsSuccess(true);
      setFormData({
        name: "",
        email: "",
        occupation: "",
        phone_number: "",
        whatsapp_number: "",
        city: "",
        reference: "",
        mode: "",
      });
    } catch (error) {
      setIsLoading(false);
      console.error("Form submission failed:", error.message);
    }
  };

  const handleCloseSuccessMessage = () => {
    setIsSuccess(false);
  };
  return (
    <div className="max-w-4xl  bg-[#FDFCFB] my-14 p-8 rounded-lg  mx-4 md:mx-auto"
      style={{
        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)"
      }}
      data-aos='fade-up'>
      <form onSubmit={handleSubmit} className="space-y-4 py-10">
        <div className="py-2">
          <label
            htmlFor="name"
            className="block text-[#666666] font-semibold"
            style={{ fontSize: "clamp(16px,1.1vw,21px)" }}
          >
            Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Enter your name"
            value={formData.name}
            onChange={handleChange}
            className="w-full mt-1 p-2 border-[2px] outline-none border-[#CCCCCC] focus:border-[#B38E5D] rounded-md"
            style={{ fontSize: "clamp(14px,1vw,19px)" }}
            required
          />
        </div>

        <div className="py-2">
          <label
            htmlFor="email"
            className="block text-[#666666] font-semibold"
            style={{ fontSize: "clamp(16px,1.1vw,21px)" }}
          >
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Enter your email"
            value={formData.email}
            onChange={handleChange}
            className="w-full mt-1 p-2 border-[2px] outline-none border-[#CCCCCC] focus:border-[#B38E5D] rounded-md"
            style={{ fontSize: "clamp(14px,1vw,19px)" }}
            required
          />
        </div>

        <div className="py-2">
          <label
            className="block text-[#666666] font-semibold"
            style={{ fontSize: "clamp(16px,1.1vw,21px)" }}
          >
            Occupation
          </label>
          <div className="mt-2">
            <label className="inline-flex items-center pt-2.5 text-[#666666]">
              <input
                type="radio"
                name="occupation"
                value="Student"
                checked={formData.occupation === "Student"}
                onChange={handleChange}
                className="form-radio  accent-[#B38E5D]"
                required
              />
              <span className="ml-2">Student</span>
            </label>
            <br />
            <label className="inline-flex items-center pt-2.5 text-[#666666]">
              <input
                type="radio"
                name="occupation"
                value="Professional"
                checked={formData.occupation === "Professional"}
                onChange={handleChange}
                className="form-radio  accent-[#B38E5D]"
              />
              <span className="ml-2">Professional</span>
            </label>
            <br />
            <label className="inline-flex items-center pt-2.5 text-[#666666]">
              <input
                type="radio"
                name="occupation"
                value="Others"
                checked={formData.occupation === "Others"}
                onChange={handleChange}
                className="form-radio  accent-[#B38E5D]"
              />
              <span className="ml-2">Others</span>
            </label>
          </div>
        </div>

        <div className="py-2">
          <label
            htmlFor="phone_number"
            className="block text-[#666666] font-semibold"
            style={{ fontSize: "clamp(16px,1.1vw,21px)" }}
          >
            Phone Number
          </label>
          <input
            type="text"
            id="phone_number"
            name="phone_number"
            placeholder="Enter your phone number"
            value={formData.phone_number}
            onChange={handleChange}
            className="w-full mt-1 p-2 border-[2px] outline-none border-[#CCCCCC] focus:border-[#B38E5D] rounded-md"
            style={{ fontSize: "clamp(14px,1vw,19px)" }}
            required
          />
        </div>

        <div className="py-2">
          <label
            htmlFor="whatsapp_number"
            className="block text-[#666666] font-semibold"
            style={{ fontSize: "clamp(16px,1.1vw,21px)" }}
          >
            WhatsApp Number
          </label>
          <input
            type="text"
            id="whatsapp_number"
            name="whatsapp_number"
            placeholder="Enter your whatsapp number"
            value={formData.whatsapp_number}
            onChange={handleChange}
            className="w-full mt-1 p-2 border-[2px] outline-none border-[#CCCCCC] focus:border-[#B38E5D] rounded-md"
            style={{ fontSize: "clamp(14px,1vw,19px)" }}
            required
          />
        </div>

        <div className="py-2">
          <label
            htmlFor="city"
            className="block text-[#666666] font-semibold"
            style={{ fontSize: "clamp(16px,1.1vw,21px)" }}
          >
            City
          </label>
          <input
            type="text"
            id="city"
            name="city"
            placeholder="Your city"
            value={formData.city}
            onChange={handleChange}
            className="w-full mt-1 p-2 border-[2px] outline-none border-[#CCCCCC] focus:border-[#B38E5D] rounded-md"
            style={{ fontSize: "clamp(14px,1vw,19px)" }}
            required
          />
        </div>

        <div className="py-2">
          <label
            htmlFor="reference"
            className="block text-[#666666] font-semibold"
            style={{ fontSize: "clamp(16px,1.1vw,21px)" }}
          >
            Reference (from where you heard about us)
          </label>
          <input
            type="text"
            id="reference"
            name="reference"
            value={formData.reference}
            placeholder="Enter refrence name"
            onChange={handleChange}
            className="w-full mt-1 p-2 border-[2px] outline-none border-[#CCCCCC] focus:border-[#B38E5D] rounded-md"
            style={{ fontSize: "clamp(14px,1vw,19px)" }}
            required
          />
        </div>

        <div className="py-2">
          <label
            className="block text-[#666666] font-semibold"
            style={{ fontSize: "clamp(16px,1.1vw,21px)" }}
          >
            How do you want to take this course?
          </label>
          <div className="mt-2">
            <label className="inline-flex items-center pt-2.5 text-[#666666]">
              <input
                type="radio"
                name="mode"
                value="Physical"
                checked={formData.mode === "Physical"}
                onChange={handleChange}
                className="form-radio  accent-[#B38E5D]"
                required
              />
              <span className="ml-2">Physical</span>
            </label>
            <br />
            <label className="inline-flex items-center pt-2.5 text-[#666666]">
              <input
                type="radio"
                name="mode"
                value="Online"
                checked={formData.mode === "Online"}
                onChange={handleChange}
                className="form-radio  accent-[#B38E5D]"
              />
              <span className="ml-2">Online</span>
            </label>
          </div>
        </div>
        <div className="flex justify-between">
          <div>
            <button
              type="submit"
              className="bg-[#B38E5D] font-semibold text-[15px] md:text-[17px] text-white px-4 py-2 duration-300 ease-in-out transition-all rounded-md hover:bg-[#88602d]"
              disabled={isLoading}
            >
              {isLoading ? "Submitting..." : "Submit"}
            </button>
          </div>
          <div>
            <button
              type="reset"
              className="bg-white text-[#B38E5D] border transition-all duration-300 hover:bg-[#B38E5D] ease-in-out hover:text-white border-[#B38E5D] px-4 py-2 rounded-md"
            >
              Reset
            </button>
          </div>
        </div>
      </form>

      {/* Success message */}
      {isSuccess && (
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-md text-center relative">
            <AiOutlineCheckCircle className="text-[#B38E5D] text-6xl mx-auto" />
            <p className="mt-4 text-lg font-semibold text-[#666666]">
              Registered Successfully!
            </p>
            <button
              className="mt-4 bg-[#B38E5D] text-white px-4 py-2 rounded-md hover:bg-[#88602d] transition-all"
              onClick={handleCloseSuccessMessage}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CourseForm;
