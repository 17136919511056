import React from 'react'
import FirstSection from './FirstSection'
import RegisterForm from './RegisterForm'

function Register() {
  return (
    <div>
        <FirstSection />
        <RegisterForm />
    </div>
  )
}

export default Register