import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from 'react-router-dom'
import profile from "../../Assets/Hero/Profile.svg";

function Hero() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <div
      className="w-full sectionlayout bg-[#EFE8D8] overflow-hidden"
      id="home"
    >
      <div className=" lg:space-x-10 min-h-[75vh] 2xl:min-h-[55vh] relative flex lg:flex-row flex-col justify-center">
        <div className="lg:w-[55%] py-10">
          <h2
            style={{ fontSize: "clamp(33px,3vw,50px)" }}
            className="text-[#3E4E41] leading-[50px] font-bold"
            data-aos="fade-right"
          >
            Learn to run your E-commerce <br /> store in 4 days. <br />
          </h2>
          <p
            className="text-[#766047] font-semibold py-7 lg:w-[60%]"
            style={{ fontSize: "clamp(24px,1.7vw,30px)" }}
            data-aos="fade-right"
            data-aos-delay="100"
          >
            Join our extensive courses and take your online store to the next
            level.
          </p>
          <Link
            to="/registration"
          >
            <button
              className="px-[17px] py-[7px] font-bold border-[2px] bg-[#B38E5D] shadow-md transition-all duration-300 ease-in-out text-[#F8F5F0] hover:bg-transparent hover:text-[#B38E5D] border-[#B38E5D] rounded-[10px]"
              data-aos="fade-right"
              data-aos-delay="200"
            >
              Register Now
            </button>
          </Link>
        </div>
        <div
          className="lg:w-[45%] w-full flex justify-center lg:justify-end items-center lg:items-end py-9 lg:py-0"
          data-aos="fade-left"
        >
          <img
            src={profile}
            alt="Profile"
            className="w-full lg:w-auto h-auto"
          />
        </div>
      </div>
    </div>
  );
}

export default Hero;
