const Navbar = {
    links: [
        {
            name: "Home",
            path: "#home"
        },
        {
            name: "About",
            path: "#about"
        },
        {
            name: "Courses",
            path: "#courses"
        },
         {
            name: "Registration",
            path: "#registration"
        },
        {
            name: "Contact",
            path: "#contact"
        }
    ]
};

export default Navbar;
