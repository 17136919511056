import React, { useEffect } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import shopify from '../../Assets/Hero/Shopify.svg'
function About() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <div className="w-full sectionlayout bg-[#EFE8D8] overflow-hidden pt-12" id='courses'>
      <div className="  relative  min-h-[75vh] 2xl:min-h-[55vh] py-12 flex  flex-col justify-center"

      >
        <h2
          style={{ fontSize: "clamp(33px,3vw,55px)" }}
          className="text-[#3E4E41] uppercase  text-center font-bold"
          data-aos="fade-up"
        >
          Welcome to Shopify Mastery
        </h2>
        <h6 className='text-[#3E4E41] uppercase text-center py-6 font-semibold' style={{ fontSize: "clamp(24px,2vw,38px)" }} data-aos="fade-right" data-aos-delay="100">
          Unlock the Full Potential of Your E-Commerce Business
        </h6>
        <h6 className='text-[#333333] uppercase  py-6 font-semibold' style={{ fontSize: "clamp(24px,2vw,38px)" }} data-aos="fade-right" data-aos-delay="100">
          What You'll Learn
        </h6>
        <div className='flex flex-col sm:flex-row'>
          <div className='w-full sm:w-[78%] '>
            <ul className='capitalize list-decimal space-y-5 font-medium' style={{ fontSize: "clamp(19px,1.4vw,30px)" }} data-aos="fade-right" >
              <ol>
                1. The fundamentals of e-commerce and why Shopify stands out
              </ol>
              <ol>
                2. Step-by-step guidance on creating and managing your Shopify store
              </ol>
              <ol>
                3. Essential tips and strategies to boost sales and drive growth
              </ol>
              <ol>
                4.  In-depth knowledge of important apps, marketing strategies, and more
              </ol>
            </ul>
          </div>
          <div className='w-full sm:w-[22%]  flex justify-end items-end' data-aos="fade-left">
            <img src={shopify} alt="Shopify" />
          </div>
        </div>
        <p className='text-[#766047] font-semibold pt-12 pb-7 text-center ' style={{ fontSize: "clamp(22px,1.9vw,36px)" }} data-aos="fade-up">

          <span className='font-bold text-[#3E4E41]'> Timings: </span> Monday to Friday, 10 AM - 6 PM
        </p>
      </div>

    </div>
  )
}

export default About
