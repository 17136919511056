import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import shopify from "../../Assets/Hero/AboutUs.svg";
function About() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <div
      className="w-full sectionlayout bg-[#F8F5F0] overflow-hidden pt-12"
      id="about"
    >
      <div className=" flex flex-col-reverse  min-h-[75vh] 2xl:min-h-[55vh] md:flex-row   relative pt-[3rem] ">
        <div className="md:w-1/2 w-full">
          <h2
            style={{ fontSize: "clamp(33px,3vw,55px)" }}
            className="text-[#3E4E41] uppercase leading-[50px] font-bold mt-6"
            data-aos="fade-right"
          >
            About Me
          </h2>
          <h6
            className="text-[#766047] py-6 font-semibold"
            style={{ fontSize: "clamp(24px,2vw,38px)" }}
            data-aos="fade-right"
            data-aos-delay="50"
          >
            Founder & E-commerce Specialist
          </h6>
          <p
            className="text-[#333333] "
            style={{ fontSize: "clamp(19px,1.5vw,27px)" }}
            data-aos="fade-right"
            data-aos-delay="100"
          >
            An authority in Shopify and e-commerce, Mian Faraz Sandhal
            specializes in maximizing online store efficiency and driving
            growth. His visionary tactics and relentless focus on service help
            businesses soar to new heights, reflecting his passion for
            excellence.
          </p>
        </div>
        <div
          className="md:w-1/2 w-full flex md:mb-0 mb-6  lg:mt-[-12vh] xl:mt-[-14vh] 2xl:mt-[-11vh]"
          data-aos="fade-left"
        >
          <img src={shopify} alt="Shopify" className="w-full " />
        </div>
      </div>
    </div>
  );
}

export default About;
