import './Navbar.css';
import React, { useEffect, useState } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import Dynamic_Leo from '../../Assets/Dynamic_Leo.png';
import dropdown from '../../Assets/icons/dropdown.svg';
import Navbar from '../../Data/Navbar';

function Desktop() {
  const [activeSection, setActiveSection] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  const handleNav = (path) => {
    const section = document.querySelector(path);
    if (section) {
      const offset = window.innerHeight * 0.12;
      const sectionTop = section.offsetTop - offset;

      window.scrollTo({
        top: sectionTop,
      });
    }
  };

  const handleLinkClick = (path) => {
    if (location.pathname !== '/') {
      navigate('/');
      setTimeout(() => handleNav(path), 100);
    } else {
      handleNav(path);
    }
  };

  const handleLogoLinkClick = () => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  };

  const handleScroll = () => {
    Navbar.links.forEach((link) => {
      const section = document.querySelector(link.path);

      if (section) {
        const sectionTop = section.offsetTop - window.innerHeight * 0.13;
        const sectionHeight = section.offsetHeight;

        if (window.scrollY >= sectionTop && window.scrollY < sectionTop + sectionHeight) {
          setActiveSection(link.path);
        }
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="w-full sectionlayout bg-[#F8F5F0] hidden lg:block sticky top-0 z-30">
      <div className="flex items-center justify-between text-[#6C6C6C] h-[14vh] border-b">
        <div>
          <NavLink to="/" onClick={handleLogoLinkClick}>
            <img className="max-h-[60px] max-w-[236px]" src={Dynamic_Leo} alt="Dynamic_Leo" />
          </NavLink>
        </div>
        <div>
          <div className="flex items-center justify-center hover:cursor-pointer">
            {Navbar.links.map((link, index) =>
              !link.submenu ? (
                <div key={index}>
                  <a
                    href={link.path}
                    onClick={(e) => {
                      e.preventDefault();
                      handleLinkClick(link.path);
                    }}
                    className={`px-5 py-2 rounded-md hover:bg-[#EFE8D8] ${
                      activeSection === link.path ? 'font-bold' : 'text-[#3E4E41] font-medium'
                    }`}
                  >
                    {link.name}
                  </a>
                </div>
              ) : (
                <div key={index} className="relative group flex dropown_menu_trigger">
                  <NavLink
                    onClick={() => handleLinkClick(link.path)}
                    to={link.path}
                    className={({ isActive }) =>
                      `px-5 py-2 rounded-md hover:bg-gray-200 flex ${
                        isActive ? 'font-bold' : 'text-gray-500'
                      }`
                    }
                  >
                    {link.name}
                    <div className="flex">
                      <img className="pl-1" src={dropdown} alt="" />
                    </div>
                  </NavLink>
                  <div className="absolute mt-10 dropown_menu z-[100]">
                    <div className="bg-gray-50 font-normal rounded-md w-[15rem] mt-2">
                      {link.submenu.map((sublink, subIndex) => (
                        <div key={subIndex} className="pl-4 pr-8 py-2 hover:bg-gray-200">
                          <NavLink
                            onClick={() => handleLinkClick(sublink.path)}
                            to={sublink.path}
                            className={({ isActive }) =>
                              isActive ? 'font-bold' : 'text-gray-500'
                            }
                          >
                            {sublink.name}
                          </NavLink>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
        <div>
          <Link
            to="/registration"
          >
            <button className="px-[17px] py-[7px] border-[2px] bg-[#B38E5D] shadow-md transition-all duration-300 ease-in-out text-white hover:bg-transparent hover:text-[#B38E5D] border-[#B38E5D] rounded-[10px] font-semibold">
              Get Started
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Desktop;
