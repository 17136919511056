import React from "react";
import registerbg from "../../Assets/Hero/register.png";
import shopify from "../../Assets/Hero/Shopify.svg";
function FirstSection() {
  return (
    <div
      className="relative bg-cover min-h-auto sectionlayout"
      style={{ 
        backgroundImage: `url(${registerbg})`,
        backgroundPosition: 'center'
      }}
    >
      <div className="absolute inset-0"></div>
      <div className="flex justify-center items-center flex-col md:flex-col space-y-5 md:space-y-0">
        <div className="relative z-10 flex items-center flex-col py-12 h-full">
          <h2
            style={{ fontSize: "clamp(27px,3vw,55px)",textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
            className="text-[#f1f1f1] uppercase text-center font-bold"
            data-aos="fade-up"
          >
            Shopify Master Course by Mian Faraz
          </h2>
          <h6
            className="text-[#FFD700] uppercase text-balance md:max-w-2xl 2xl:max-w-4xl text-center py-4 font-semibold"
            style={{ fontSize: "clamp(22px,1.9vw,38px)",textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
            data-aos="fade-up"
            data-aos-delay="50"
          >
            Register now and open your own Shopify store in just 4 days.
          </h6>
          <p
            className="text-[#ffffff]  md:max-w-2xl 2xl:max-w-4xl text-center py-4 font-medium"
            style={{ fontSize: "clamp(18px,1.4vw,30px)",textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
            data-aos="fade-up"
            data-aos-delay="50"
          >
            This course is designed to enable you to run your store yourself. Whether you are beginner or a professional, this course is optimized for you. Explore the world of e-commerce, and turn your business into a thriving digital hub.
          </p>
        </div>
        <div className="z-20 md:my-0 my-10 flex items-center justify-end w-full">
          <img src={shopify} alt="Shopify" className="h-[8rem] md:h-auto mb-8 md:mb-0" />
        </div>
      </div>
    </div>
  );
}
export default FirstSection;
