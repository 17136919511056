import React from 'react'
import './Home.css'
import Hero from './Hero'
import About from './About'
import CourseDetails from './CourseDetails'
import Register from './Register';
import ContactUs from './ContactUs'
function Home() {
  return (
    <>
      <Hero />
      <About />
      <CourseDetails />
      <Register />
      <ContactUs />
    </>
  )
}

export default Home
