import Dynamic_Leo from "../../Assets/Dynamic_Leo.png";
import right_arrow_white from "../../Assets/icons/right_arrow_white.svg";
import { useLocation, useNavigate } from 'react-router-dom';
import Social from "../../Components/Social/Social";
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function Footer() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  const location = useLocation();
  const navigate = useNavigate();

  const handleNav = (path) => {
    const section = document.querySelector(path);
    if (section) {
      const offset = window.innerHeight * 0.12;
      const sectionTop = section.offsetTop - offset;

      window.scrollTo({
        top: sectionTop,
      });
    }
  };

  const handleNavLinkFooter = (path) => {
    if (location.pathname !== '/') {
      navigate('/');
      setTimeout(() => handleNav(path), 100);
    } else {
      handleNav(path);
    }
  }

  return (
    <div className="w-full bg-[#ffffff] overflow-hidden pb-5 ">
      <div
        className="w-full sectionlayout poppins "
        data-aos="fade-up"
        data-aos-offset="-200"
      >
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 text-[#6c6c6c] py-8">
          <div>
            <img
              src={Dynamic_Leo}
              alt={Dynamic_Leo}
              className="max-h-[9vh] max-w-[70%] md:max-h-[60px] md:max-w-[220px]"
              data-aos="fade-up"
              data-aos-offset="-200"
            />
            <p
              className="mt-4 text-[14px]"
              data-aos="fade-up"
              data-aos-offset="-200"
            >
              Gulberg Green Islamabad
            </p>
            <p
              className="mt-4 text-[14px]"
              data-aos="fade-up"
              data-aos-offset="-200"
            >
              info@dynamicleo.com
            </p>
            <p
              className="mt-4 text-[14px]"
              data-aos="fade-up"
              data-aos-offset="-200"
            >
              +92 304 1110898
            </p>
          </div>
          <div>
            <p
              className="text-[18px] font-semibold mt-8 md:mt-1 mb-4 relative"
              data-aos="fade-up"
              data-aos-offset="-200"
            >
              Quick Links{" "}
              <span className="absolute left-0 bottom-[-20px] w-[50px] h-[4px] bg-[#b6861f]"></span>
            </p>
            <p
              onClick={(e) => {
                e.preventDefault();
                handleNavLinkFooter("#home");
              }}
              className="mt-[35px] md:mt-[55px] text-[14px] cursor-pointer"
              data-aos="fade-up"
              data-aos-offset="-200"
            >
              Home
            </p>
            <p
              onClick={(e) => {
                e.preventDefault();
                handleNavLinkFooter("#about");
              }}
              className="mt-3 text-[14px] cursor-pointer"
              data-aos="fade-up"
              data-aos-offset="-200"
            >
              About
            </p>
            <p
              onClick={(e) => {
                e.preventDefault();
                handleNavLinkFooter("#courses");
              }}
              className="mt-3 text-[14px] cursor-pointer"
              data-aos="fade-up"
              data-aos-offset="-200"
            >
              Courses
            </p>
            <p
              onClick={(e) => {
                e.preventDefault();
                handleNavLinkFooter("#contact");
              }}
              className="mt-3 text-[14px] cursor-pointer"
              data-aos="fade-up"
              data-aos-offset="-200"
            >
              Contact Us
            </p>
          </div>
          <div>
            <p
              className="text-[18px] font-semibold mt-8 md:mt-1 mb-4 relative"
              data-aos="fade-up"
              data-aos-offset="-200"
            >
              Services{" "}
              <span className="absolute left-0 bottom-[-20px] w-[50px] h-[4px] bg-[#b6861f]"></span>
            </p>
            <a href="https://dynamicleoecommerce.com/" target="_blank" rel="noopener noreferrer">
              <p
                className="mt-[35px] md:mt-[55px] text-[14px]"
                data-aos="fade-up"
                data-aos-offset="-200"
              >
                E-Commerce
              </p>
            </a>
            <a href="https://dynamicleoitsolutions.com/" target="_blank" rel="noopener noreferrer">
              <p
                className="mt-3 text-[14px]"
                data-aos="fade-up"
                data-aos-offset="-200"
              >
                IT-Solutionse
              </p>
            </a>
            <a href="/" target="_blank" rel="noopener noreferrer">
              <p
                className="mt-3 text-[14px]"
                data-aos="fade-up"
                data-aos-offset="-200"
              >
                Dropshipping
              </p>
            </a>
          </div>
          <div>
            <p
              className="text-[18px] font-semibold  mt-8 lg:mt-1 mb-4 relative"
              data-aos="fade-up"
              data-aos-offset="-200"
            >
              Subscribe{" "}
              <span className="absolute left-0 bottom-[-20px] w-[50px] h-[4px] bg-[#b6861f]"></span>
            </p>
            <div
              className="md:w-full mt-[45px] flex mb-5"
              data-aos="fade-left"
              data-aos-offset="-200"
            >
              <input
                type="text"
                placeholder="Get product updates"
                className="p-4 w-[80%] border font-normal text-[14px] rounded-tl-[10px] rounded-bl-[10px] focus:border-blue-500 focus:outline-none"
              />
              <button
                type="submit"
                className="p-4 w-[20%] font-normal text-[14px] bg-[#478EFF] flex items-center justify-center rounded-tr-[10px] rounded-br-[10px]"
              >
                <img src={right_arrow_white} alt="" />
              </button>
            </div>
            <div
              className="flex md:block items-center justify-center"
              data-aos="fade-up"
              data-aos-offset="-200"
            >
              <Social />
            </div>
          </div>
        </div>
        <p className="text-[#6c6c6c] text-center md:text-left">
          © 2024 Dynamic Leo. All rights reserved
        </p>
      </div>
    </div>
  );
}

export default Footer;
