import './index.css'
import React from 'react';
import { Routes, Route} from "react-router-dom";
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import Home from './Pages/Home/Home'
import Register from './Pages/Register/Register';

function App() {
  return (
    <>
      <Navbar/>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/registration" element={<Register />} />
      </Routes>
      <Footer/>
    </>
  );
}
export default App;

